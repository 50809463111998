<template>
  <div>
    <v-select
      id="vSelectCompanyName"
      v-model="selected_companies"
      :multiple="multiple"
      :placeholder="placeholder"
      :options="all_companies"
      :class="validator && selected_companies.company_name ? '': 'danger-select' "
      label="company_name"
      @search="companySearch"
    />

  </div>
</template>

<script>

import { debounce } from 'lodash'
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    placeholder: { type: String, default: 'Select the company/companies you belong to' },
    stateErrors: { type: Array, default: null },
    multiple: { type: Boolean, default: false },
    validator: { type: Boolean, default: false },
    clearSelect: { type: Boolean, default: false },
  },
  data() {
    return {
      all_companies: [],
      selected_companies: {},
    }
  },
  watch: {
    selected_companies(val) {
      this.$emit('company-selected', val)
    },
    clearSelect(val) {
      if (val) {
        this.selected_companies = this.multiple ? [] : {}
      }
    },
  },
  mounted() {
    this.selected_companies = this.multiple ? [] : {}
  },

  methods: {

    companySearch(search, loading) {
      if (search.length > 0) {
        loading(true)
        this.searchForCompany(loading, search, this)
      }
    },
    searchForCompany: debounce((loading, search, vm) => {
      vm.getEmployers(search, loading)
    }, 1000),

    getEmployers(query, loading = null) {
      this.all_companies = []
      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          this.all_companies = []
          const record = response.data

          if (record.success) {
            record.data.map(data => {
              const item = {
                company_name: data.company_name,
                id: data.id,
                company_uen_no: data.uen_no,
              }
              this.all_companies.push(item)
            })
          }
          if (loading) {
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>
