/* eslint-disable prefer-destructuring */ /* eslint-disable no-trailing-spaces
*/ /* eslint-disable no-restricted-syntax */
<template>
  <div
    id="div-with-loading"
    class="w-100 text-din-regular text-pantone-445"
    :style="accountancy_event ? 'background-color: #41a7ae;' : ''"
  >
    <CeaseBanner v-if="$route.name !== 'view-jobs'" />

    <AppNavbarFull
      v-if="showLayout"
      :company-image="group_event_image"
    />

    <b-row v-if="specific_company">
      <b-card
        v-if="specific_company_image"
        class="w-100 mx-auto"
      >
        <div class="">
          <h3>All events by :</h3>
        </div>

        <div class="">
          <img
            :src="`${$bucketUrl}/company-profile-image/${specific_company_image}`"
            alt="content-img"
            class="image-size mx-auto self-center"
          >
        </div>
      </b-card>
    </b-row>
    <div
      v-if="false"
      class="d-flex justify-content-center mt-4 mb-4 mx-2"
    >
      <img
        v-if="group_event_image !== 'default'"
        :src="
          `${$bucketUrl}/${group_event_image}`
        "
        alt="content-img"
        class="image-size mx-auto"
        @click="checkRedirection"
      >
    </div>
    <!-- :class="{'mt-4 mb-4': $route.name === 'listings-page'}" -->
    <div
      v-if="$route.name === 'listings-page'"
      class="main-section banner-container d-flex flex-wrap jusitfy-content-center w-100 bg-main-background"
    >
      <b-row class="m-0 p-0 w-100 d-flex align-items-center">
        <b-col
          cols="12"
          md="4"
          class="p-0 mt-0 mt-md-0 mb-0"
        >
          <!-- <div class="d-flex flex-wrap justify-content-between align-items-center " >
            <img
              :src="logoImage"
              style="height: 4rem"
              class="w-auto object-scale-down"
            >

          </div> -->
          <div class="my-1 d-flex d-md-none">
            <img
              v-if="['listings-page', 'listings-event'].includes($route.name)"
              :src="
                `${group_event_image}`
              "
              alt="content-img"
              class="image-size mx-auto"
              @click="checkRedirection"
            >
          </div>
          <p
            class="mt-0 mb-0 big-title text-din-black"
            style="line-height: 3.5rem;"
          >
            <span class="text-primary">Design </span> <br>
            <span class="">Jobs </span>
          </p>

          <div
            class="d-flex text-din-bold"
            style="gap: 2rem;"
          >
            <div class="py-1">
              Explore new and exciting career opportunities in design
            </div>
          </div>
        </b-col>

        <!-- :class="{'mt-2 mb-2': ['listings-page', 'listings-event'].includes($route.name)}" -->
        <b-col
          cols="12"
          md="8"
          class="p-0 d-none d-md-flex justify-content-end"
        >
          <img
            v-if="['listings-page', 'listings-event'].includes($route.name)"
            :src="
              `${group_event_image}`
            "
            alt="content-img"
            class="image-size"
            @click="checkRedirection"
          >
        </b-col>
      </b-row>
    </div>

    <div class="my-2">
      <div class="p-8">
        <div class="container-fluid px-4 mb-2">
          <b-row class="w-100 mx-auto">
            <h4
              class="mx-auto"
              :class="accountancy_event ? 'text-white' : 'text-dark'"
            >
              Search by Job Title
            </h4>
          </b-row>
          <b-row class="">
            <b-input-group class="">
              <!-- <b-form-input
                v-model="selected_event_title"
                class="w-auto"
                :class="{'w-75': isMobile}"
                placeholder="Start typing to search for a specific job title"
                @keyup="searchText"
              /> -->
              <v-select
                id="vSelectJobPosition"
                v-model="selected_event_title"
                style="width: 100%!important;"
                class="w-auto"
                :class="{'w-75': isMobile}"
                placeholder="Start typing to search for a specific job title"
                :options="all_positions"
                label="value"
                :reduce="option => option.text"
                @open="positionSearch"
                @close="searchText"
                @search="positionSearch"
              />

              <!-- <b-input-group-append
                :class="{'w-25': isMobile}"
              >
                <b-button
                  variant="primary"
                  class="w-100"
                  @click="specificGetEvents()"
                >
                  <feather-icon
                    class="mr-md-1"
                    icon="SearchIcon"
                    size="15"
                  />
                  <span v-if="!isMobile">Search</span>
                </b-button>
              </b-input-group-append> -->
            </b-input-group>
          </b-row>

          <b-modal
            id="modal-lg"
            ref="share-job-modal"
            centered
            hide-footer
            title="Share Job"
          >
            <div class="d-flex flex-column text-din-bold">
              <h6 class="mx-auto">
                The link that will be shared.
              </h6>
              <b-form-input
                v-model="share_link"
                class="mt-1 mx-auto text-din-regular"
              />
            </div>
            <div class="d-flex flex-wrap justify-content-center p-2 text-din-regular">
              <ShareNetwork
                v-for="(info, index) of all_share_icons"
                :key="index"
                style="margin-inline: .8rem;"
                :network="info.network"
                :url="getLink(current_item.id)"
                :title="
                  'Check out this ' +
                    current_item.position +
                    ' event organised by ' +
                    current_item.company_name +
                    ' via HeadHunters HQ.'
                "
              >
                <font-awesome-icon
                  :icon="info.name"
                  size="3x"
                  :class="info.styling"
                />
              </ShareNetwork>
            </div>
          </b-modal>
        </div>

        <b-modal
          id="modal-lg"
          ref="filter-job-modal"
          centered
          hide-footer
          title="Job Type"
        >
          <div class="mt-1 mb-1">
            <v-select
              v-model="selected_job_type"
              placeholder="Choose your preferred job type"
              :options="job_types"
              label="text"
              multiple
              class="text-white"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-position-modal"
          centered
          hide-footer
          title="Employment Industry"
        >
          <div
            class="mt-1 mb-1"
          >
            <v-select
              v-model="selected_industry"
              placeholder="Choose your preferred employer industry"
              multiple
              :options="industries"
              class=""
              label="text"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employer-modal"
          centered
          hide-footer
          title="Employer Name"
        >
          <div>
            <v-select
              v-model="select_company"
              placeholder="Choose an employer"
              multiple
              label="value"
              class="text-white"
              :options="companies"
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-work-modal"
          centered
          hide-footer
          title="Work Location"
        >
          <div>
            <v-select
              v-model="selected_location"
              placeholder="Choose your preferred work location"
              multiple
              label="value"
              :options="all_locations"
              class="text-white"
              :reduce="(value) => value.value"
            />
          </div>

        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-salary-modal"
          centered
          hide-footer
          title="Salary Range"
        >
          <div>
            <h6 class="">
              Per Annum
            </h6>
            <vue-slider
              v-model="salary_range_yearly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="100"
              :tooltip-formatter="salary_formatter"
            />
            <h6 class="">
              Per Monthly
            </h6>
            <vue-slider
              v-model="salary_range_monthly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="10"
              :tooltip-formatter="salary_formatter"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-employment-modal"
          centered
          hide-footer
          title="Employment Type"
        >
          <div>
            <v-select
              v-model="selected_employment_type"
              placeholder="Choose your preferred employment type"
              multiple
              label="text"
              class="text-white"
              :options="employment_types"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <b-modal
          id="modal-lg"
          ref="filter-sort-modal"
          centered
          hide-footer
          title="Sort by"
        >

          <div>
            <h6 class="">
              By Position
            </h6>
            <v-select
              v-model="sort_choice"
              label="value"
              class=""
              :options="sort_options"
              :reduce="(value) => value.value"
            />
          </div>
        </b-modal>

        <div
          v-if="false"
          :class="{ 'invisible': !isMobile }"
          class="filter-button-container"
        >
          <div class="filter-button-box">
            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterSort()"
            >
              <span class="align-middle"> Sort By</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmployer()"
            >
              <span class="align-middle">Employer Name</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterJobLevel()"
            >
              <span class="align-middle"> Position Level</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterSalary()"
            >
              <span class="align-middle"> Salary Range</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEvent()"
            >
              <span class="align-middle"> Job Type</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmployment()"
            >
              <span class="align-middle"> Employment Industry</span>
            </b-button>

            <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterEmploymentIndustry()"
            >
              <span class="align-middle"> Employment Industry</span>
            </b-button>

            <!-- <b-button
              class="filter-button btn-icon d-block d-lg-none"
              :class="{'w-100': isMobile}"
              variant="primary"
              @click="filterWork()"
            >
              <span class="align-middle"> Work Location</span>
            </b-button> -->
          </div>
        </div>

        <div class="d-flex flex-column flex-sm-column flex-lg-row w-100">
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->
          <b-col
            class=""
            cols="12"
            lg="4"
            sm="12"
          >
            <div class="p-8">
              <!-- <b-card :class="{'d-none' : isMobile}"> -->

              <b-overlay
                :show="searchLoading"
                rounded="sm"
              >
                <b-card class="">
                  <div class="w-100 p-0 p-md-1">
                    <div
                      class="d-flex flex-row flex-wrap"
                      :class="
                        expand_search
                          ? 'justify-content-between'
                          : 'justify-content-end'
                      "
                    >
                      <b-button
                        v-if="expand_search"
                        class="align-left"
                        :class="{'w-100': isMobile}"
                        style="width: 150px"
                        block
                        variant="outline-primary"
                        @click="resetFilters"
                      >
                        <feather-icon
                          icon="XCircleIcon"
                          style="margin-right: 4px; margin-bottom: 1px"
                        />
                        <span class="align-middle">Reset Filters</span></b-button>

                      <b-button
                        class="btn-icon d-block d-lg-none shadow-sm"
                        :class="{
                          'w-100': isMobile,
                          'mt-1': expand_search,
                        }"
                        variant="primary"
                        @click="expand_search = !expand_search"
                      >
                        <feather-icon
                          :icon="
                            expand_search ? 'Minimize2Icon' : 'Maximize2Icon'
                          "
                        />
                        <span class="align-middle">{{
                          expand_search ? " Minimize Filters Panel" : " Maximize Filters Panel"
                        }}</span>
                      </b-button>
                    </div>

                    <b-form-group class="container-fluidkkk">
                      <!-- v-if="expand_search" -->
                      <div
                        v-if="false"
                        class="mt-2 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Sort Positions By
                        </span>

                        <v-select
                          v-model="sort_choice"
                          label="value"
                          class="v-select-dark-text"
                          :options="sort_options"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <!-- v-if="companies.length > 0 && expand_search" -->
                      <div
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Employer Name
                        </span>
                        <v-select
                          v-model="select_company"
                          placeholder="Choose an employer"
                          multiple
                          label="value"
                          class="text-white"
                          :options="companies"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="employment_types.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Employment Type
                        </span>
                        <v-select
                          v-model="selected_employment_type"
                          placeholder="Choose your preferred employment type"
                          multiple
                          label="text"
                          class="text-white"
                          :options="employment_types"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="job_types.length > 0 && expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Job Type
                        </span>
                        <v-select
                          v-model="selected_job_type"
                          class="text-white"
                          placeholder="Choose your preferred job type"
                          :options="job_types"
                          label="text"
                          multiple
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="expand_search"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Position Level
                        </span>
                        <v-select
                          v-model="selected_job_level"
                          placeholder="Choose your preferred job level"
                          multiple
                          label="value"
                          :options="jobLevelOptions"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="
                          !specific_company &&
                            organiser_companies.length > 0 &&
                            expand_search
                        "
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Event Organiser Name
                        </span>
                        <v-select
                          v-model="select_organiser"
                          placeholder="Choose an event organiser"
                          :options="organiser_companies"
                          multiple
                          label="value"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div> 

                      <!-- v-if="all_locations.length > 0 && expand_search" -->
                      <div
                        v-if="false"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Work Location
                        </span>
                        <v-select
                          v-model="selected_location"
                          placeholder="Choose your preferred work location"
                          multiple
                          label="value"
                          :options="all_locations"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div> 

                      <!-- v-if="industries.length > 0 && expand_search" -->
                      <div
                        v-if="expand_search"
                        class="mt-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Employer Industry
                        </span>
                        <v-select
                          v-model="selected_industry"
                          placeholder="Choose your preferred employer industry"
                          multiple
                          label="text"
                          :options="industries"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <!-- v-if="all_job_functions.length > 0 && expand_search" -->
                      <div
                        v-if="false"
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Job Function
                        </span>
                        <v-select
                          v-model="selected_job_function"
                          placeholder="Choose your preferred job function"
                          multiple
                          label="value"
                          :options="all_job_functions"
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>
                      <div
                        v-if="
                          !specific_company &&
                            all_dates.length > 0 &&
                            expand_search
                        "
                        class="mt-1 mb-1"
                      >
                        <span :class="`${filterTitleClass}`">
                          Date Of Event
                        </span>
                        <v-select
                          v-model="select_date"
                          label="value"
                          placeholder="Choose the start date of the event"
                          :options="all_dates"
                          multiple
                          class="text-white"
                          :reduce="(value) => value.value"
                        />
                      </div>

                      <div
                        v-if="expand_search"
                        class="mt-1 mb-1"
                      >
                        <div :class="`d-flex flex-row justify-content-between aligh-items-center ${filterTitleClass}`">
                          <div> Salary Range </div>

                          <div class="d-flex flex-row flex-gap-sm">
                            <b-button
                              :variant="salaryRangeFilterType === 'month' ? 'primary' : 'outline-primary'"
                              size="sm"
                              @click="changeSalaryRangeFilterType('month')"
                            >
                              Per Month
                            </b-button>
                            <b-button
                              :variant="salaryRangeFilterType === 'annum' ? 'primary' : 'outline-primary'"
                              size="sm"
                              @click="changeSalaryRangeFilterType('annum')"
                            >
                              Per Annum
                            </b-button>
                          </div>
                        </div>

                        <div class="d-flex flex-column">
                          <vue-slider
                            v-if="salaryRangeFilterType === 'annum'"
                            v-model="salary_range_yearly"
                            :enable-cross="false"
                            class="mt-3"
                            :contained="true"
                            :silent="true"
                            :tooltip="'always'"
                            :tooltip-placement="['top', 'top']"
                            :min="min_salary_range"
                            :max="max_salary_range"
                            :interval="1"
                            :tooltip-formatter="salary_formatter"
                          />
                        </div>

                        <vue-slider
                          v-if="salaryRangeFilterType === 'month'"
                          v-model="salary_range_monthly"
                          :enable-cross="false"
                          class="mt-3"
                          :contained="true"
                          :silent="true"
                          :tooltip="'always'"
                          :tooltip-placement="['top', 'top']"
                          :min="min_salary_range"
                          :max="max_salary_range"
                          :interval="1"
                          :tooltip-formatter="salary_formatter"
                        />

                        <!-- <vue-slider
                          v-if="renderSalaryRange"
                          v-model="salary_range"
                          :enable-cross="false"
                          class="w-100 mt-3 cursor-pointer"
                          :silent="true"
                          :tooltip="'always'"
                          :min="min_salary_range"
                          :max="max_salary_range"
                          :interval="3"
                          :tooltip-formatter="salary_formatter"
                        /> -->
                      </div>
                    </b-form-group>
                  </div>
                </b-card>
              </b-overlay>
            </div>
          </b-col>

          <b-overlay
            :show="jobsLoading"
            rounded="sm"
            class="w-100"
            cols="12"
            lg="8"
            sm="12"
          >
            <b-col
              v-if="true"
              id="jobs_loading"
              class="w-100"
              sm="12"
            >
              <h4 class="mb-1">
                Showing {{ events_data.length }} of {{ events_data.length > serverParams.totalRecords ? events_data.length : serverParams.totalRecords }} jobs
                {{ isFiltered ? ' based on your filters' : '' }}
              </h4>
              <div
                v-for="(item, index) in events_data"
                :id="`jobItem-${item.id}`"
                :key="index"
                class="w-100"
              >
                <b-card class="d-flex flex-column w-100 p-0 p-md-1">
                  <b-row class="w-100 m-0 p-0">
                    <b-col
                      lg="9"
                      sm="12"
                      class="p-0"
                    >
                      <b-badge
                        v-if="item.featured && !isFiltered"
                        class="mb-1"
                        style="margin-top: -20px;"
                        variant="dark"
                      >
                        <div class="d-flex align-contents-center">
                          <feather-icon
                            icon="StarIcon"
                          /> &nbsp;
                          Featured
                        </div>
                      </b-badge>

                      <div class="w-100">
                        <div class="d-flex flex-wrap">
                          <h6
                            v-if="item.event_category === 'join'"
                            class="text-regular text-din-bold italic"
                          >
                            <span>{{ item.company_name }}</span>
                          </h6>
                          <h5
                            v-else
                            class="text-regular text-din-bold italic"
                          >
                            <em>{{ item.company_name }}</em>
                          </h5>

                        </div>

                        <div class="d-flex flex-wrap">
                          <h2
                            class="text-primary text-din-bold mx-0 px-0"
                            style="margin-left: 5px;"
                          >
                            {{ item.position }}
                          </h2>
                        </div>

                        <!-- v-if="item.event_name" -->
                        <b-row
                          v-if="false"
                          class="ml-1"
                        >
                          <p
                            v-if="item.organiser_name && !grouped()"
                            style="font-weight: 600; font-size: 14px"
                          >
                            <a
                              v-if="false"
                              :href="
                                base_listings + item.event_id + '_g'
                              "
                            >{{ item.event_name }}</a>
                            event
                            {{
                              cleanerIntervals(
                                item.cleaned_start_date,
                                item.cleaned_end_date
                              )
                            }}
                            ({{ item.cleaned_start_time }} to
                            {{ item.cleaned_end_time }})
                          </p>
                        </b-row>

                        <div
                          class="d-flex flex-wrap flex-gap-sm"
                          style="margin-bottom: .8rem;"
                        >
                          <div
                            v-for="(tag, index) in item.employment_type"
                            :key="index"
                          >
                            <!-- Sugianto: previously was preferences -->
                            <b-badge
                              pill
                              color="warning"
                            >
                              {{ cleanerTag(tag) }}
                            </b-badge>
                          </div>
                        </div>

                        <div
                          class="d-flex flex-wrap"
                          style="font-weight: 600; font-size: 14px"
                        >

                          <div
                            v-if="false"
                            class="d-flex justify-content-between mr-1"
                          >
                            <font-awesome-icon
                              icon="columns"
                              class="lg"
                            />
                            <h6 class="icon-header">
                              {{ getEventType(item.interview_type) }}
                            </h6>
                          </div>

                          <div
                            v-if="false"
                            class="d-flex justify-content-between mr-1"
                          >
                            <font-awesome-icon icon="map-marker-alt" />

                            <h6 class="icon-header">
                              {{
                                cleanLocation(
                                  item.work_location,
                                  item.location_zone
                                )
                              }}
                            </h6>
                          </div>

                          <div class="d-flex align-items-start justify-content-between mr-1">
                            <!-- <font-awesome-icon icon="fa-solid fa-clock" /> -->
                            <!-- <font-awesome-icon :icon="['fas', 'clock']" /> -->
                            <div class="d-flex">
                              <feather-icon
                                v-b-tooltip.hover.top="`Job Type`"
                                style="margin-top: .05rem;"
                                icon="ClockIcon"
                              />
                            </div>

                            <div class="d-flex">
                              <h6 class="icon-header">
                                {{ item.job_type }}
                              </h6>
                            </div>
                          </div>

                          <div class="d-flex align-items-start justify-content-between mr-1">
                            <div class="d-flex">
                              <feather-icon
                                v-b-tooltip.hover.top="`Position Level`"
                                style="margin-top: .05rem;"
                                icon="AwardIcon"
                              />
                            </div>

                            <div class="d-flex">
                              <h6 class="icon-header">
                                {{ item.job_level }}
                              </h6>
                            </div>
                          </div>

                          <div class="d-flex align-items-start justify-content-between mr-1">
                            <div class="d-flex">
                              <feather-icon
                                v-b-tooltip.hover.top="`Industry`"
                                style="margin-top: .05rem;"
                                icon="BriefcaseIcon"
                              />
                            </div>

                            <div class="d-flex">
                              <h6 class="d-flex flex-wrap icon-header">
                                {{ item.company_industry ? item.company_industry.join(", "): "NA" }}
                              </h6>
                            </div>
                          </div>

                          <div class="flex-break" />

                          <div class="d-flex">
                            <div class="d-flex">
                              <font-awesome-icon
                                v-b-tooltip.hover.top="`Salary`"
                                style="margin-top: .1rem;"
                                icon="money-bill"
                              />
                            </div>

                            <h6
                              v-if="item.min_salary == item.max_salary"
                              class="icon-header"
                            >
                              ${{
                                item.max_salary === 1 || item.max_salary === "1" ? "Undisclosed" : item.max_salary
                              }}
                              {{
                                item.max_salary === 1 || item.max_salary === "1"
                                  ? ""
                                  : cleanCycles(item.salary_cycle)
                              }}
                            </h6>

                            <h6
                              v-else-if="
                                item.max_salary === 'To be discussed later'
                              "
                              class="icon-header"
                            >
                              ${{ item.max_salary }}
                            </h6>

                            <h6
                              v-else
                              class="icon-header"
                            >
                              ${{ $formatCurrency(item.min_salary) }} - ${{ $formatCurrency(item.max_salary) }}
                              {{ cleanCycles(item.salary_cycle) }}
                            </h6>
                          </div>
                        </div>

                        <div
                          v-if="item.industry && item.industry.length > 0"
                          class="d-flex"
                        >
                          <div
                            class="d-flex flex-wrap flex-gap-sm"
                          >
                            <div
                              v-for="(tag, index) in item.industry"
                              :key="index"
                              class="d-flex"
                            >
                              <b-badge
                                pill
                                variant="dark"
                              >
                                {{ tag }}
                              </b-badge>
                            </div>
                          </div>
                        </div>
                        <!-- v-if="item.organiser_name" -->
                        <h6
                          v-if="false"
                          class="mt-1 ml-1 fw-b"
                          style="font-size: 14px"
                        >
                          <span
                            v-if="item.organiser_name && !grouped()"
                          >An event organised by {{ item.organiser_name }}</span>
                        </h6>

                        <!-- v-else -->
                        <h6
                          v-if="false"
                          class="my-1 text-danger ml-1"
                          style="font-size: 14px"
                        >
                          <span>When you click `Apply Now` button you will be
                            redirected to NTUC's Job Security Council - Jobs
                            Alert telegram bot where you will complete the
                            application.</span>
                        </h6>

                        <div
                          v-if="['mcf', 'efc'].includes(item.source_name)"
                          class="d-flex"
                        >
                          <h6
                            class="mt-1 text-warning font-weight-bold"
                            style="font-weight: 600 !important"
                          >
                            Source: {{ item.source_name === 'mcf' ? 'MyCareersFuture': 'eFinancialCareers' }}
                          </h6>
                          <font-awesome-icon
                            v-b-tooltip.hover.top="mcf_message"
                            icon="question-circle"
                            class="mt-1 ml-1 text-warning"
                          />
                        </div>

                        <div class="pr-0 pr-md-1">
                          <p
                            v-if="!item.external"
                            class="text-din-bold text-right"
                            style="font-size: 14px"
                          >
                            {{ item.number_of_applications ? item.number_of_applications : 0 }} Applications
                          </p>
                          <p
                            v-if="item.external"
                            class="fw-bold text-right"
                            style="font-weight: 400; font-size: 14px"
                          >
                            {{ item.applications }} Applications
                          </p>
                        </div>

                      </div>

                    </b-col>

                    <b-col
                      lg="3"
                      sm="12"
                      class="p-0 w-100"
                    >
                      <div class="d-flex flex-row w-100">
                        <div class="divider d-block d-md-none" />

                        <div class="d-flex flex-column w-100">
                          <div
                            v-if="item.event_category !== 'group'"
                            class="d-flex flex-column w-100"
                          >
                            <b-button
                              class="mx-auto mt-1 mt-md-0 text-center"
                              :to="'/jobs/' + item.id"
                              variant="primary"
                              block
                            >
                              <feather-icon
                                icon="BookOpenIcon"
                                class="mr-1"
                              />
                              <span
                                class="align-middle"
                              >View More</span></b-button>

                            <div
                              v-if="false"
                              class="d-flex flex-column w-100"
                            >
                              <b-button
                                :id="`buttonApplyNow-redirect-job_${item.id}`"
                                ref="join"
                                class="mx-auto mt-2 w-100"
                                variant="primary"
                                block
                                @click="fireRedirect(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class=""
                                />
                                <span class="align-middle">Apply Now</span>
                              </b-button>
                            </div>
                            <div v-else>
                              <b-button
                                v-if="
                                  showEventButtons
                                    && checkIDRegistered( registered, item.id) && !checkProfileCompletion()
                                "
                                ref="join"
                                class="mx-auto mt-2 w-100"
                                variant="success"
                                block
                                @click="joinEvent(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-1"
                                />
                                <span
                                  class="align-middle"
                                >Applied</span></b-button>
                              <div
                                v-else-if="
                                  checkIDRegistered(
                                    registered,
                                    item.id
                                  ) && checkProfileCompletion()
                                "
                                class="d-flex flex-column"
                              >
                                <b-button
                                  variant="warning"
                                  class="mx-auto mt-2 w-100"
                                  block
                                  @click="partialReg"
                                >
                                  <feather-icon
                                    icon="CheckIcon"
                                    class="mr-1"
                                  />
                                  <span class="align-middle">
                                    Incomplete Application
                                  </span>
                                </b-button>

                                <b-alert
                                  v-if="
                                    checkIDRegistered(
                                      registered,
                                      item.id
                                    ) && checkProfileCompletion()
                                  "
                                  class="w-100 h-auto"
                                  variant="warning"
                                  show
                                >
                                  <div class="alert-body">
                                    <feather-icon
                                      class="mr-2"
                                      icon="infoIcon"
                                    />
                                    <span>
                                      Complete it via
                                      {{
                                        $router.currentRoute.path == "/events-list"
                                          ? "the Profile page"
                                          : `Manage My Application`
                                      }}
                                    </span>
                                  </div>
                                </b-alert>
                              </div>

                              <b-button
                                v-else
                                :id="`buttonApplyNow-form-job_${item.id}`"
                                ref="join"
                                class="mx-auto mt-2"
                                variant="primary"
                                block
                                @click="joinEvent(item, $event)"
                              >
                                <feather-icon
                                  icon="CheckIcon"
                                  class="mr-1"
                                />
                                <span
                                  class="align-middle"
                                >Apply Now</span>
                              </b-button>
                            </div>

                            <b-button
                              class="mx-auto mt-2 w-100"
                              variant="primary"
                              block
                              @click="shareEvent(item)"
                            >
                              <feather-icon
                                icon="ShareIcon"
                                class="mr-1"
                              />
                              <span
                                class="align-middle"
                              >Share Job</span></b-button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <hr v-if="item.note">
                  <b-row v-if="item.note">
                    <p
                      style="font-weight: 600; font-size: 14px;white-space: pre-line;"
                      class="mx-2 mt-1"
                    >
                      <span>
                        Note:
                      </span>
                      <br>
                      <span>
                        {{ item.note }}
                      </span>
                    </p>
                  </b-row>
                </b-card>
              </div>

              <div
                v-if="events_data.length > 0"
                class="d-flex w-100 mb-16"
              >
                <b-button
                  v-if="loadedAllJobs"
                  class="mx-auto"
                  variant="primary"
                  @click="loadMoreJobs"
                >View More Jobs</b-button>
                <p
                  v-else
                  class="vx-col mx-auto"
                >
                  No more available jobs
                </p>
                <!-- <vs-pagination
                class="vx-col sm:ml-12 lg:ml-0"
                :total="total_events.length"
                v-model="current_job_page"
                :max="10"
              ></vs-pagination> -->
              </div>
            </b-col>
            <b-col
              v-else
              class="w-100"
            >
              <h4
                class="grey mx-auto text-center mt-5"
                :class="accountancy_event ? 'text-white' : 'text-dark'"
              >
                {{ event_exist ? 'No available jobs under this section currently.': 'This event has been closed or does not exists.' }}
              </h4>
            </b-col>
          </b-overlay>
        </div>
        <!-- </div> -->
      </div>

      <!-- RegistrationForm Modal -->
      <div class="">
        <b-modal
          ref="popupReg"
          v-model="popupReg"
          centered
          hide-footer
          size="lg"
        >
          <div class="w-100 text-din-regular">
            <h6 v-if="false" class="mb-6">
              You will require an account to proceed with the
              application.
            </h6>
            <b-tabs justified>
              <b-tab title="Sign In">
                <login-page
                  class="w-100"
                  which-page="listings"
                  :event-id="this.$router.currentRoute.path"
                  @user-logged-in="afterUserLoggedIn"
                />
              </b-tab>
              <b-tab title="Registration">
                <RegistrationForm
                  class="w-100"
                  :extra-data="popup_questions"
                  :event-id="event_id"
                  which-page="listings"
                  account-type="Applicant"
                  @user-registered="afterUserRegistered"
                />
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>

      <!-- Quiz Modal -->
      <JobPopup
        :activate-popup="popup_quiz"
        :job-questions="popup_questions"
        :save-nric="save_nric"
        :all-applications="registered"
        @application-form="checkForm"
        @application-id="setApplicationId"
      />
      <ProfileCompletionForm
        :activate-popup="popupProfileQuestions"
        @open-job-form="getQuestions"
        @open-completion-form="activateTypeForm"
      />
    </div>
    <div
      v-if="showLayout"
      class="container-fluid"
    >
      <AppFooter class="w-100" />
    </div>

    <help-desks-issue-floating />
  </div>
</template>

<script>
import HelpDesksIssueFloating from '@/components/HelpDesksIssueFloating.vue'
import designCouncilImage from '@/assets/images/pages/listings/DESIGNSINGAPORE COUNCIL PTE. LTD - cropped..png'
// import designCouncilBannerImage from '@/assets/images/pages/listings/photo1684334190.jpeg'
// import designCouncilBannerImage from '@/assets/images/pages/listings/dsg-banner.jpg'
// import designCouncilBannerImage from '@/assets/images/pages/listings/Teal_Illustration_Digital_Business_Blog_Banner_1200_×_540.svg'
import designCouncilBannerImage from '@/assets/images/pages/listings/modern_isometric_illustration_design_hr.png'
import designCouncilLogo from '@/assets/images/logo/dsg-transparent-logo.png'

import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBTooltip,
  VBModal,
  BBadge,
  BOverlay,
  BAlert,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-slider-component/theme/default.css'
import {
  intersection, orderBy, toInteger, inRange,
} from 'lodash'
import AppNavbarFull from '@/layouts/components/AppNavbarFull.vue'
import AppFooter from '@/layouts/components/AppFooter.vue'

import {
  allIndustries,
  specialIndustries,
  jobFunctions,
} from '@/constants/datastore'

import { isUserLoggedIn } from '@/auth/utils'
import JobPopup from '@/components/JobPopup.vue'
import LoginPage from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { listingsMixin } from './mixins/listingsMixins'


export default {
  components: {
    HelpDesksIssueFloating,

    // ListEvent,
    JobPopup,
    AppFooter,
    VueSlider,
    vSelect,
    BCard,
    BBadge,
    BAlert,
    BFormGroup,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,

    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    AppNavbarFull,

    LoginPage,
    RegistrationForm,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [listingsMixin, onResizeMixins],
  props: {
    groupEventId: { type: String, default: '' },
  },

  data() {
    return {
      isFiltered: false,
      logoImage: designCouncilLogo,

      all_positions: [],
      filterModalState: {
        filter_job_level: false,
      },
      preferred_pos: [],
      isLoading: false,
      all_share_icons: [
        {
          name: ['fab', 'facebook-square'],
          styling: 'facebook-color',
          network: 'facebook',
        },
        {
          name: ['fab', 'twitter-square'],
          styling: 'twitter-color',
          network: 'twitter',
        },
        {
          name: ['fab', 'whatsapp-square'],
          styling: 'whatsapp-color',
          network: 'whatsapp',
        },
        {
          name: ['fab', 'linkedin'],
          styling: 'linkedin-color',
          network: 'linkedin',
        },
        {
          name: ['fab', 'facebook-messenger'],
          styling: 'messenger-color',
          network: 'messenger',
        },
        {
          name: ['fab', 'telegram'],
          styling: 'telegram-color',
          network: 'telegram',
        },
      ],
      book: {
        text: 'Register',
        color: 'primary',
      },
      select_event_type: '',
      event_types: [
        { text: 'Single events', value: 'single' },
        { text: 'Group events', value: 'group' },
      ],
      group_preview_popup: false,
      group_preview_link: '',
      selected_job_type: [],
      selected_job_level: [],
      sort_choice: 'Default',
      sort_options: [
        { text: 'Default', value: 'Default' },
        { text: 'Ascending', value: 'Ascending' },
        { text: 'Descending', value: 'Descending' },
      ],
      selected_industry: [],
      industries: [],

      selected_employment_type: [],
      jobLevelOptions: [
        { test: 'Senior Management', value: 'Senior Management' },
        { test: 'Middle Management', value: 'Middle Management' },
        { test: 'Manager', value: 'Manager' },
        { test: 'Professional', value: 'Professional' },
        { test: 'Senior Executive', value: 'Senior Executive' },
        { test: 'Executive', value: 'Executive' },
        { test: 'Junior Executive', value: 'Junior Executive' },
        { test: 'Non-executive', value: 'Non-executive' },
        { test: 'Fresh/Entry level', value: 'Fresh/Entry level' },
      ],
      job_types: [
        { text: 'Part-time', value: 'parttime' },
        { text: 'Full-time', value: 'fulltime' },
      ],
      employment_types: [
        { text: 'Permanent', value: 'permanent' },
        { text: 'Contract', value: 'contract' },
        { text: 'Flexi-work', value: 'flexiwork' },
        { text: 'Temporary', value: 'temporary' },
        { text: 'Freelance', value: 'freelance' },
        { text: 'Internship/Traineeship', value: 'internship_traineeship' },
      ],
      salary_formatter: v => `S$${`${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      selected_location: '',
      selected_event_title: '',
      redirection_link: null,
      all_locations: [
        {
          text: 'Islandwide',
          value: 'Islandwide',
        },
        {
          text: 'North',
          value: 'North',
        },
        {
          text: 'South',
          value: 'South',
        },
        {
          text: 'Central',
          value: 'Central',
        },
        {
          text: 'East',
          value: 'East',
        },
        {
          text: 'West',
          value: 'West',
        },
      ],
      all_dates: [],
      specific_company: '',
      specific_company_image: '',
      event_id: '',
      fullname: '',
      email_address: '',
      show_inside: false,
      popupActive: false,
      image_url: 'default',
      event_exist: false,
      action: 'insert',
      current_item: {},
      popupReg: false,

      // Employments
      popupEmployments: false,
      // Educations
      popupEducations: false,

      item_id: '',
      job: {},
      companies: [],
      registered: [],
      events_data: [],
      all_events_data: [],
      select_tags: [],
      select_date: [],
      select_company: [],
      select_organiser: [],
      organiser_companies: [],
      current_user: '',
      position: '',
      description: '',
      salary_range: [0, 240000],
      salary_range_monthly: [0, Math.round(240000 / 12)],
      salary_range_yearly: [0, 240000],
      min_salary_range: 0,
      max_salary_range: 240000,
      categories: [
        { text: 'Administration, business and management', value: 1 },
        { text: 'Alternative therapies', value: 2 },
        { text: 'Animals, land and environment', value: 3 },
        { text: 'Computing and ICT', value: 4 },
        { text: 'Construction and building', value: 5 },
        { text: 'Design, arts and crafts', value: 6 },
        { text: 'Education and training', value: 7 },
        { text: 'Engineering', value: 8 },
        { text: 'Facilities and property services', value: 9 },
        { text: 'Financial services', value: 10 },
        { text: 'Garage services', value: 11 },
        { text: 'Hairdressing and beauty', value: 12 },
        { text: 'Healthcare', value: 13 },
        { text: 'Heritage, culture and libraries', value: 14 },
        { text: 'Hospitality, catering and tourism', value: 15 },
        { text: 'Legal and court services', value: 16 },
        { text: 'Manufacturing and production', value: 17 },
        { text: 'Performing arts and media', value: 18 },
        { text: 'Retail and customer services', value: 19 },
        { text: 'Science, mathematics and statistics', value: 20 },
        { text: 'Security, uniformed and protective services', value: 21 },
        { text: 'Social sciences and religion', value: 22 },
        { text: 'Sport and leisure', value: 23 },
        { text: 'Transport, distribution and logistics', value: 24 },
      ],
      dates: [],
      popup_event: {},
      current_event_id: '',
      share_popup: false,
      filter_popup: false,
      group_event_image: false,
      share_link: '',
      searchTimer: null,
      selected_status: true,
      all_event_status: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      all_job_functions: [],
      selected_job_function: [],
      total_events: [],
      start_page_count: 0,
      end_page_count: 0,
      current_job_page: 1,
      page_size: 4,
      accountancy_event: false,
      pause_trigger: false,

      load_more_counter: 4,
      expand_icon: 'icon-minimize-2',
      expand_search: true,
      companyImage: '',
      showLayout: true,

      base_listings: 'http://dashboard.headhuntershq.com/listings/',
      // base_listings: 'http://localhost:8082/listings/',
      searchLoading: false,
      jobsLoading: false,
      mcf_message:
        'All information on jobs is published in good faith. DSG does not make any warranties about the completeness, reliability and accuracy of this information. From the jobs portal, you can visit other websites by following hyperlinks on the job ads to external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove any dead/unrelated/inappropriate content or links.',
      listingsPagination: {
        currentPage: 0,
        skipPages: 0,
        limit: 5,
      },
      activeFilters: {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      },

      showEventButtons: true, // use to rerender buttons after submitting so it can turn to applied button

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
        totalRecords: 0,
      },

      renderSalaryRange: true,
      salaryRangeFilterType: 'annum',
      filterTitleClass: 'my-1 text-din-bold',

    }
  },
  computed: {

    eventsGroups() {
      return Array.from(Array(Math.ceil(this.events_data.length / 4)).keys())
    },
    loadedAllJobs() {
      // if (this.load_more_counter < this.all_events_data.length) {
      //   return true
      // }

      // return true
      if (this.events_data.length === this.serverParams.totalRecords) {
        return false
      }

      return true
    },
  },
  watch: {
    current_job_page: {
      handler(val, oldVal) {
        this.events_data = this.total_events[val - 1]
      },
    },

    selected_event_title: {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.serverParams.page = 1
          this.serverParams.perPage = 5
          this.serverParams.totalRecords = 0
        }
        if (!val && oldVal != null) {
          this.searchText()
        }
      },
    },
    salary_range_monthly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          // if (val[1] !== 0) {
          //   this.salary_range_yearly = [0, 0]
          // }
          this.salary_range = [val[0] * 12, val[1] * 12]
        }
      },
    },
    salary_range_yearly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          // if (val[1] !== 0) {
          //   this.salary_range_monthly = [0, 0]
          // }
          this.salary_range = [val[0], val[1]]
        }
      },
    },
    selected_job_level: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.searchText()
        }
        this.$addParamsToUrl('filtered_job_level', val)
      },
    },
    salary_range: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.searchText()
        }

        this.$addParamsToUrl('filtered_salary', val)
      },
    },
    selected_employment_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_employment_types', val)
      },
    },
    selected_job_function: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_job_functions', val)
      },
    },
    select_tags: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
      },
    },
    select_date: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_dates', val)
      },
    },
    select_company: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_companies', val)
      },
    },

    select_organiser: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_organisers', val)
      },
    },
    selected_location: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_locations', val)
      },
    },
    selected_job_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_job_types', val)
      },
    },

    selected_industry: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('filtered_industries', val)
      },
    },

    select_event_type: {
      handler(val, oldVal) {
        this.serverParams.page = 1
        this.specificGetEvents()
      },
    },

    sort_choice: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1
          this.specificGetEvents()
        }
        this.$addParamsToUrl('sort_position', val)
      },
    },
    popup_quiz: {
      handler(val, oldVal) {
      },
    },
    popupProfileQuestions: {
      handler(val, oldVal) {
      },
    },
    deep: true,
  },

  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}
    // this.events_data = [
    //   {
    //     "min_salary": 1,
    //     "max_salary": 1000,
    //     "salary_cycle": "Monthly",
    //     "industry": [],
    //     "featured": false,
    //     "interview_type": "virtual",
    //     "job_functions": null,
    //     "employment_type": [
    //       "internship_traineeship",
    //       "permanent"
    //     ],
    //     "industry_1": [],
    //     "employer_industry": ['Staffing and Recruiting'],
    //     "job_level": "Manager",
    //     "working_location_address": '',
    //     "working_location_state": "Singapore",
    //     "working_location_country": "Singapore",
    //     "working_location_postal_code": null,
    //     "source_name": null,
    //     "application_url": null,
    //     "event_id": "cc04238b-e18f-4d74-adaf-598d1a99b6ba",
    //     "event_name": "Design Job",
    //     "start_date": "1st May 2023",
    //     "end_date": "19th Jun 2023",
    //     "start_time": "01:00:00",
    //     "end_time": "15:00:00",
    //     "event_banner": "event-images/bbe073da-782b-4105-90e1-4dd1b638d734_1684378578.jpeg",
    //     "job_questions": [
    //       {
    //         "id": "0c587090-351e-42f0-9796-ba9330303c73",
    //         "job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
    //         "question": "Event screening question?"
    //       },
    //       {
    //         "id": "4c687829-5ccb-4875-8df3-937d4eebf643",
    //         "job_id": "90b6dabd-eace-4b34-b737-7716b2e94dbf",
    //         "question": "Have you ever create a promotional video?"
    //       }
    //     ],
    //     "event_questions": [
    //       {
    //         "event_id": "cc04238b-e18f-4d74-adaf-598d1a99b6ba",
    //         "id": "0f85e306-f65d-4a6a-8e11-3fcb2dd1d38a",
    //         "question": "Event screening question?"
    //       }
    //     ],
    //     "company_name": "Express Delivery Inc",
    //     "company_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "organiser_name": "Employment and Employability Inc",
    //     "organiser_image": "company-profile-images/bbe073da-782b-4105-90e1-4dd1b638d734.jpg",
    //     "job_applications": null,
    //     "last_postal_code": null,
    //     "location_zone": null,
    //     "company": "Express Delivery Inc",
    //     "external": false,
    //     "note": null,
    //     "event_category": "join",
    //     "work_location": {
    //       "address": null,
    //       "country": "Singapore",
    //       "postal_code": null
    //     },
    //     "cleaned_start_date": "1st May",
    //     "cleaned_end_date": "19th Jun 2023",
    //     "cleaned_start_time": "01:00 AM",
    //     "cleaned_end_time": "03:00 PM",

    //     job_id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
    //     id: '013fbcaf-ec3f-447b-aceb-78385affa0da',
    //     position: 'Sales Associate',
    //     employer_name: 'CHROMA FURNISHING PTE. LTD.',
    //     job_type: 'Full-Time',
    //     employment_type: ['Permanent'],
    //     working_days: '6 Days',
    //     working_time: 'Shift Hours',
    //     working_location_address: 'Oxley Bizhub 2, 62 Ubi Road 1',
    //     working_location_state: 'Singapore',
    //     working_location_country: 'Singapore',
    //     working_location_postal_code: '408734',
    //     min_salary: 'S$1',
    //     max_salary: 'S$1,000',
    //     cleaned_start_date: '1st Jun 2023',
    //     cleaned_end_date: '1st Jul 2023',
    //     cleaned_start_time: '09:00',
    //     cleaned_end_time: '16:00',

    //     company_uen_no: '201721612D',
    //     uen_no: '201721612D',
    //     company_name: 'HEADHUNTERS HQ PTE. LTD.',
    //     company_status: true,
    //     company_summary: 'HeadHunters HQ is a HR Tech marketplace for Talent Acquisition/Recruitment Solutions that seeks to connect Recruitment Agencies, Direct Employers and Job Seekers together. The marketplace houses integrated modular solutions and with each of these solutions designed and engineered to streamline existing processes and empower organisations with the cutting edge to hire faster, better and the right talent.',
    //   },
    // ]
    this.event_exist = true
    this.group_event_image = designCouncilBannerImage
  },
  created() {
    // repopulate the filters from the URL
    this.checkIsFiltered()

    if (this.$router.currentRoute.path.includes('/listings')) {
      this.showLayout = true
    } else {
      this.showLayout = false
    }
    if (window.screen.width < 769) {
      this.expand_search = false
    }
    this.end_page_count = this.page_size
    this.current_event_id = this.$route.params.event_id
    // this.getUser()
    // const { token } = this.$store.state.auth.ActiveUser
    this.email_address = this.$store.state.auth.ActiveUser
      ? this.$store.state.auth.ActiveUser.email_address
      : null
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    // this.industries = allIndustries

    this.industries = orderBy(this.industries, ['text'], ['asc'])
    // this.all_locations = orderBy(this.all_locations, ['text'], ['asc'])
    // this.employment_types = orderBy(this.employment_types, ['text'], ['asc'])
    this.job_types = orderBy(this.job_types, ['text'], ['asc'])

    // typeform
    this.prefIndustryOptions = orderBy(allIndustries, ['value'], ['asc'])

    this.searchLoading = false
    this.jobsLoading = false

    if (this.groupEventId.length > 2) {
      this.getEvents(this.groupEventId, 'group_event')
    }
    this.specificGetEvents()
  },
  methods: {
    onSalaryRangeChange() {
    },
    checkIsFiltered() {
      if (!this.$isEmpty(this.$route.query)) {
        this.pause_trigger = true
        Object.entries(this.$route.query).forEach(([key, value]) => {
          switch (key) {
            case 'sort_position':
              this.sort_choice = value
              if (value !== 'Default') {
                this.isFiltered = true
              }
              break
            case 'filtered_companies':
              this.select_company = value
              this.isFiltered = true
              break
            case 'filtered_job_level':
              this.selected_job_level = value
              this.isFiltered = true
              break
            case 'filtered_locations':
              this.selected_location = value
              this.isFiltered = true
              break
            case 'filtered_salary':
              this.salary_range = value
              if (value[0] !== '0' && value[1] !== '240000') {
                this.isFiltered = true
              }
              break
            case 'filtered_job_types':
              this.selected_job_type = value
              this.isFiltered = true
              break
            case 'filtered_employment_types':
              this.selected_employment_type = value
              this.isFiltered = true
              break
            case 'filtered_industries':
              let newValue = []
              if (typeof value === 'object') {
                value.forEach(item => {
                  newValue.push(item.split(','))
                })
              } else {
                newValue = value
              }

              this.selected_industry = newValue
              this.isFiltered = true
              break

            default:
              break
          }
        })
      }
    },
    positionSearch(query, loading = null) {
      this.all_positions = []

      const params = `&params=${encodeURIComponent(JSON.stringify(this.serverParams))}`

      this.$http
        .get(`/api/position-search?query=${query}${params}`)
        .then(response => {
          const record = response.data

          if (record.success) {
            this.all_positions = record.data
            // record.data.map(data => {
            //   this.all_positions.push(data)
            // })
          }
          if (this.$isNotEmpty(query)) {
            this.all_positions.unshift({
              text: query,
              value: query,
            })
          }

          if (loading) {
            loading(false)
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    updateApplications(val) {
      this.registered = val
      this.$forceUpdate()
    },
    checkForm(val) {
      this.popup_quiz = val
    },
    activateTypeForm(val) {
      this.popupProfileQuestions = val
    },
    getQuestions(val) {
      this.popup_quiz = true
      this.popup_questions = val.questions
      this.save_nric = val.save_nric
    },
    checkProfileCompletion() {
      let incomplete = true
      try {
        incomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
      } catch (error) {
        incomplete = true
      }
      return incomplete
    },
    loadMoreJobs() {
      if (!isUserLoggedIn()) {
        this.popupReg = true
        return
      }
      this.listingsPagination.currentPage += 5
      this.listingsPagination.skipPages += 5

      this.serverParams.page += 1
      this.specificGetEvents()
    },
    cleanLocation(location, zone) {
      let { address } = location
      const { country } = location
      address = !this.$isEmpty(address) ? address.replace(`${country} `, '') : address

      let currentZone = zone
      if (currentZone === undefined || currentZone === null) {
        currentZone = 'NA'
      }
      return `${currentZone} (${location.country} ${location.postal_code})`.replace(' null', '').replace('null', '')
    },
    partialReg() {
      this.$bvModal
        .msgBoxConfirm(
          "Currently, your application isn't viewable by the employer since you haven't completed your profile."
            + ' \n\nClicking the button below will redirect you to your profile page where you can fill up the required details.',
          {
            title: 'Incomplete Profile',
            size: 'lg',
            okVariant: 'warning',
            okTitle: 'My Profile',
            cancelTitle: 'Go Back',
            cancelVariant: 'primary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            window.location.href = '/profile'
          }
        })
    },

    resetFilters() {
      this.activeFilters = {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      }
      this.salaryRangeFilterType = 'annum'
      this.selected_event_title = ''
      this.pause_trigger = true
      this.sort_choice = 'Default'
      this.select_company = []
      this.select_organiser = []
      this.selected_location = ''
      this.salary_range = [0, 240000]
      this.selected_job_type = []
      this.selected_employment_type = []
      this.selected_industry = []
      this.selected_job_function = []
      this.selected_job_level = []
      this.select_date = []
      this.isFiltered = false
      this.specificGetEvents()
      //
    },
    grouped() {
      const { company } = this.$route.params
      return company
    },
    fireRedirect(item) {
      window.open(item.redirection_url, '_blank')
    },

    checkRedirection() {
      if (this.redirection_link) {
        window.open(this.redirection_link, '_blank')
      }
    },
    specificGetEvents() {
      this.jobsLoading = true
      this.searchLoading = true
      this.load_more_counter = 4
      if (this.$route.params.company !== undefined) {
        this.specific_company = this.$route.params.company

        if (this.$route.params.company.endsWith('_g')) {
          const eventId = this.$route.params.company.replace('_g', '')
          this.getEvents(eventId, 'group_event')
        } else {
          const eventId = this.$route.params.company
          this.getEvents(this.$route.params.company_id, 'employer')
        }
      } else {
        this.getEvents()
      }
    },
    cleanCycles(dirtyCycle) {
      const equivCycles = {
        yearly: 'per Year',
        monthly: 'per Month',
        weekly: 'per Week',
        daily: 'per Day',
        hourly: 'per Hour',
      }

      const cleanedCycle = !this.$isEmpty(dirtyCycle) ? equivCycles[dirtyCycle.toLowerCase()] : ''

      return cleanedCycle
    },
    searchText() {
      clearTimeout(this.searchTimer)

      this.searchTimer = setTimeout(() => {
        this.specificGetEvents()
      }, 1000)
    },
    shareEvent(item) {
      this.current_item = item
      this.share_link = this.getLink(item.id)
      this.$refs['share-job-modal'].toggle('#toggle-btn')
      this.share_popup = true
    },
    filterEvent() {
      this.$refs['filter-job-modal'].toggle('#toggle-btn')
    },
    filterPosition() {
      this.$refs['filter-position-modal'].toggle('#toggle-btn')
    },
    filterEmployer() {
      this.$refs['filter-employer-modal'].toggle('#toggle-btn')
    },
    filterWork() {
      this.$refs['filter-work-modal'].toggle('#toggle-btn')
    },
    filterSalary() {
      this.$refs['filter-salary-modal'].toggle('#toggle-btn')
    },
    filterEmployment() {
      this.$refs['filter-employment-modal'].toggle('#toggle-btn')
    },
    filterSort() {
      this.$refs['filter-sort-modal'].toggle('#toggle-btn')
    },
    getEventType(value) {
      if (value === 'virtual') {
        return 'Virtual Interview'
      }
      if (value === 'physical') {
        return 'In-Person Interview'
      }
      if (value === 'speed_interview') {
        return 'Virtual Speed Interview'
      }
      return 'NA'
    },
    goToEvents(eventId) {
      this.event_id = eventId

      localStorage.setItem('current_event', eventId)
      // let route = this.$router.resolve({ path: "/events/" + event_id });
      //   this.group_preview_link = route.href
      this.group_preview_popup = true
      // let route = this.$router.resolve({ path: "/events/" + event_id });

      // window.open(route.href, "_blank");
    },
    getLink(eventId) {
      return `${window.location.origin}/jobs/${eventId}`
    },
    onCopy(eventId) {
      this.copyTextToClipboard(`${window.location.origin}/events/${eventId}`)
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Job Link Alert',
            icon: 'AlertCircleIcon',
            text: 'Job link copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
    },

    bookEvent() {
      if (this.book.text === 'Applied') {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Job Application Update',
              icon: 'AlertCircleIcon',
              text: 'You have already applied for this job.',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.email_address.length > 4 && this.fullname.length > 3) {
        const all_data = {
          event_id: this.event_id,
          email_address: this.email_address,
          fullname: this.fullname,
          approved: false,
          interviewed: false,
          company: this.$store.state.auth.ActiveUser.company_name,
        }
        this.$http
          .post('/api/book', { data: all_data })
          .then(response => {
            const { success } = response.data
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Update',
                    icon: 'AlertCircleIcon',
                    text: response.data.message,
                    variant: 'danger',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {})
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Invalid Data',
              icon: 'AlertCircleIcon',
              text: 'Please enter a valid email address or full name',
              variant: 'danger',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    viewMore(item) {
      localStorage.setItem('current_event', item.id)
      this.show_inside = true
      this.job = item
      this.popupActive = true
    },
    trimMyString(string) {
      const start = 0
      const maxLength = 200
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength)
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')),
        )} ......`
      }
      return string
    },
    filter_events(data) {
      // this.$vs.loading({ container: "#div-with-loading", scale: 0.6 });

      let new_events = false
      const min_salary = toInteger(data.min_salary)
      const max_salary = toInteger(data.salary)
      const salary_cycle = data.salary_cycle.toLowerCase()
      let multiplier = 1

      if (salary_cycle === 'yearly') {
        multiplier = 1
      } else if (salary_cycle === 'weekly') {
        multiplier = 52
      }
      const salary = inRange(
        min_salary * multiplier,
        this.salary_range[0],
        this.salary_range[1],
      )
        || inRange(
          max_salary * multiplier,
          this.salary_range[0],
          this.salary_range[1],
        )

      const comp = this.select_company.length > 0
        ? this.select_company.includes(data.company)
        : true

      const organiser = this.select_organiser.length > 0
        ? this.select_organiser.includes(data.organiser)
        : true
      const location = this.selected_location.length > 0
        ? this.selected_location.includes(data.location_zone)
        : true

      // let status = this.selected_status.length > 0 ? this.selected_status : true;
      const similar_industry = intersection(this.selected_industry, data.tags)

      const tags = this.selected_industry.length > 0 ? similar_industry.length > 0 : true

      const event_type = this.select_event_type.length > 0
        ? this.select_event_type.includes(data.event_category)
        : true

      const similar_job_functions = intersection(
        this.selected_job_function,
        data.job_functions,
      )
      const job_function = this.selected_job_function.length > 0
        ? similar_job_functions.length > 0
        : true

      const employment_type = this.selected_employment_type.length > 0
        ? this.selected_employment_type.some(v => data.employment_type.includes(v))
        : true

      const job_type = this.selected_job_type.length > 0
        ? this.selected_job_type.includes(data.job_type)
        : true

      const date = this.select_date.length > 0
        ? this.select_date.includes(data.start_date)
        : true

      const title = this.selected_event_title && this.selected_event_title.length > 0
        ? data.position
          .toLowerCase()
          .includes(this.selected_event_title.toLowerCase())
        : true

      if (
        comp
        && organiser
        && tags
        && location
        && event_type
        && job_type
        && date
        && title
        && job_function
        && employment_type
        && salary
      ) {
        new_events = true
      }

      return new_events
      // this.events_data = new_events;
      // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },

    smartFilter(data) {
      const { available_job_functions } = data
      const { available_job_types } = data
      const { available_industries } = data
      const { available_employment_types } = data
      const { available_employer_industries } = data
      const { available_job_levels } = data

      if (available_job_functions.length > 0) {
        this.all_job_functions = available_job_functions.map(industry => ({ text: industry, value: industry }))
      } else {
        this.all_job_functions = []
      }

      if (available_job_types.length > 0) {
        this.job_types = this.job_types.filter(jobType => (jobType.value.length > 0
          ? available_job_types.includes(jobType.value)
          : true))
      } else {
        // this.job_types = []
      }

      if (available_industries.length > 0) {
        this.industries = available_industries.map(industry => ({ text: industry, value: industry }))
      } else {
        // this.industries = []
      }
      this.industries = available_employer_industries
      this.industries = orderBy(this.industries, ['text'], ['asc'])

      if (available_employment_types.length > 0) {
        this.employment_types = this.employment_types.filter(empTypes => available_employment_types.includes(empTypes.value))
      } else {
        this.employment_types = []
      }


      this.jobLevelOptions = available_job_levels
      // this.jobLevelOptions = orderBy(this.jobLevelOptions, ['text'], ['asc'])
    },
    firstSelection(field) {
      if (!this.activeFilters[field]) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
        this.listingsPagination.skipPages = 0
      }

      this.activeFilters[field] = true
    },
    getEvents(companyFilter, special = '') {
      const { email_address } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { email_address: null }

      this.current_user = email_address

      const companyName = this.$route.params.company_name

      const all_event_group = []

      function attachSymbol(text) {
        return text.length > 0 ? '&' : '?'
      }

      this.serverParams.columnFilters = {}

      if (special === 'group_event') {
        this.serverParams.columnFilters.group_event = companyFilter
      }

      if (this.salary_range.length > 0) {
        const minSalary = this.salary_range[0]
        const maxSalary = this.salary_range[1]

        // if (this.salaryRangeFilterType === 'month') { // turns it back to yearly
        //   alert(minSalary)
        //   alert(maxSalary)
        //   minSalary = Math.floor(minSalary * 12)
        //   maxSalary = Math.ceil(maxSalary * 12)
        // }

        this.serverParams.columnFilters.filtered_salary = [minSalary, maxSalary]

        let weeklySalaryRangeNotFiltered = false
        if (this.salaryRangeFilterType === 'month') { // turns it back to yearly
          if (Number(this.salary_range[0]) / 12 === this.min_salary_range && Number(this.salary_range[1]) / 12 === this.max_salary_range) {
            weeklySalaryRangeNotFiltered = true
          }
        }

        if (
          (Number(this.min_salary_range) === 0 && Number(this.max_salary_range) === 240000)
            || (Number(this.salary_range[0]) === this.min_salary_range && Number(this.salary_range[1]) === this.max_salary_range)
            || weeklySalaryRangeNotFiltered
        ) {
          this.isFiltered = false
          this.serverParams.columnFilters.filtered_salary = [0, 999999999]

          this.pause_trigger = true
          this.salary_range = [this.min_salary_range, this.max_salary_range]
        } else {
          this.isFiltered = true
        }
      }

      if (this.sort_choice !== 'Default') {
        this.serverParams.columnFilters.sort_position = this.sort_choice
        this.firstSelection('sort')
        // this.serverParams.columnFilters.sort_position = this.sort_choice
        this.isFiltered = true
      }

      if (companyName) {
        this.serverParams.columnFilters.company_name = companyName
      }

      if (this.selected_event_title && this.selected_event_title.length > 0) {
        this.serverParams.columnFilters.query = this.selected_event_title
        this.isFiltered = true
        this.firstSelection('search')
      }

      if (this.select_company.length > 0) {
        this.serverParams.columnFilters.filtered_companies = JSON.stringify(this.select_company)
        this.isFiltered = true
        this.firstSelection('employer')
      }

      if (this.select_organiser.length > 0) {
        this.serverParams.columnFilters.filtered_organisers = this.select_organiser
        this.isFiltered = true
      }

      if (this.selected_location.length > 0) {
        this.serverParams.columnFilters.filtered_locations = this.selected_location
        this.isFiltered = true
        this.firstSelection('location')
      }

      if (this.selected_employment_type.length > 0) {
        this.serverParams.columnFilters.filtered_employment_types = this.selected_employment_type
        this.isFiltered = true
        this.firstSelection('employment_type')
      }

      if (this.selected_industry.length > 0) {
        this.serverParams.columnFilters.filtered_industries = JSON.stringify(this.selected_industry)
        this.isFiltered = true
        this.firstSelection('selected_industry')
      }

      if (this.selected_job_function.length > 0) {
        this.serverParams.columnFilters.filtered_job_functions = this.selected_job_function
        this.isFiltered = true
        this.firstSelection('selected_job_function')
      }

      if (this.select_date.length > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date
        this.isFiltered = true
        this.firstSelection('select_date')
      }

      if (this.selected_job_level.length > 0) {
        this.serverParams.columnFilters.filtered_job_level = this.selected_job_level
        this.isFiltered = true
        this.firstSelection('selected_job_level')
      }

      if (this.select_date.length > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date
      }

      if (this.selected_job_type.length > 0) {
        this.serverParams.columnFilters.filtered_job_types = this.selected_job_type
        this.isFiltered = true
        this.firstSelection('selected_industry')
      }

      let query = ''
      if (this.serverParams.page === 1) {
        this.events_data = []
        this.all_events_data = []
        this.registered = {}
      }

      query = `?params=${encodeURIComponent(JSON.stringify(this.serverParams))}`

      this.$http
        .get(`/api/all-events${query}`)
        .then(response => {
          if (response.data.success) {
            this.event_exist = true
            this.registered = {
              ...this.registered,
              ...response.data.registered,
            }
            this.companies = response.data.companies
            this.organiser_companies = response.data.organisers

            this.companies = orderBy(this.companies, ['text'], ['asc'])
            this.organiser_companies = orderBy(
              this.organiser_companies,
              ['text'],
              ['asc'],
            )

            if (this.salaryRangeFilterType === 'annum') {
              this.min_salary_range = Math.round(response.data.min_max_salary[0] * 12)
              this.max_salary_range = Math.round(response.data.min_max_salary[1] * 12)
              if (this.salary_range[0] === 0 && this.salary_range[1] === 240000) {
                this.salary_range_yearly = [this.min_salary_range, this.max_salary_range]
              }
            } else if (this.salaryRangeFilterType === 'month') {
              this.renderSalaryRange = false
              this.min_salary_range = response.data.min_max_salary[0]
              this.max_salary_range = response.data.min_max_salary[1]
              setTimeout(() => {
                this.renderSalaryRange = true
              }, 300)
            }

            this.all_locations = response.data.locations
            const jobFunctions = response.data.available_job_functions.map(item => ({ text: item, value: item }))

            this.all_job_functions = orderBy(jobFunctions, ['text'], ['asc'])
            this.all_dates = response.data.all_dates
            this.all_dates = orderBy(this.all_dates, ['text'], ['asc'])
            this.smartFilter(response.data)

            // eslint-disable-next-line no-restricted-syntax
            for (const item of response.data.message) {
              const output = item
              this.events_data.push(item)
              if (this.$route.params.company) {
                this.companyImage = item.company_image
                this.$emit('image-update', item.organiser_image)
                // this.group_event_image = item.event_banner
                this.group_event_image = designCouncilBannerImage
              }
            }
            // console.table(response.data)
            this.serverParams.totalRecords = response.data.total_records
            // this.serverParams.totalRecords = 2
          } else {
            this.event_exist = false
            this.searchLoading = false
            this.serverParams.totalRecords = 0
          }

          setTimeout(() => {
            this.pause_trigger = false
          }, 500)
          this.jobsLoading = false
          this.searchLoading = false
        })
        .catch(error => {
          this.$handleErrorResponse(error)
          this.pause_trigger = false
          this.jobsLoading = false
          this.searchLoading = false
        })

      // this.$http
      //   .get(`/api/top-events`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
      // this.$http
      //   .get(`/api/top-jobs`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
    },
    extract_values() {
      const selected_tags = []
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text)
          }
        }
      }
      let selected_date = ''
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      }
      return all_data
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    cleanerTag(tag) {
      if (!this.$isEmpty(tag)) {
        const splitted = tag.split('_')
        const new_splitted = []
        splitted.forEach(word => {
          new_splitted.push(this.$capitalizeString(word))
        })

        return new_splitted.join(' ')
      }
    },

    cleanerIntervals(start_date, end_date) {
      if (start_date === end_date) {
        return `happening on ${start_date}`
      }
      return `happening from ${start_date} to ${end_date}`
    },

    checkIDRegistered(registered, job_id) {
      const id = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser.id
        : null
      if (registered) {
        if (Object.keys(registered).includes(job_id)) {
          return registered[job_id].includes(id)
        }
        return false
      }
      return false
    },
    $addParamsToUrl(keyName, value) {
      // this.$router.push({ path: this.$route.fullPath, query: { [keyName]: value } })
      if (this.$isNotEmpty(value)) {
        this.$router.push({ path: this.$route.fullPath, query: { [keyName]: value } })
      }
    },
    $removeAllParamsToUrl() {
      this.$router.push({ path: this.$route.fullPath })
    },

    changeSalaryRangeFilterType(newType) {
      if (this.salaryRangeFilterType === 'month') {
        if (newType === 'annum') {
          this.min_salary_range = Math.floor(this.min_salary_range * 12)
          this.max_salary_range = Math.ceil(this.max_salary_range * 12)

          // this.salary_range = [
          //   Math.floor(this.salary_range[0] * 12),
          //   Math.ceil(this.salary_range[1] * 12)
          // ]
          this.salary_range_yearly = [
            this.min_salary_range,
            this.max_salary_range,
          ]
        }
      } else if (this.salaryRangeFilterType === 'annum') {
        if (newType === 'month') {
          this.min_salary_range = Math.floor(this.min_salary_range / 12)
          this.max_salary_range = Math.ceil(this.max_salary_range / 12)

          // this.salary_range = [
          //   Math.floor(this.salary_range[0] / 12),
          //   Math.ceil(this.salary_range[1] / 12)
          // ]
          this.salary_range_monthly = [
            this.min_salary_range,
            this.max_salary_range,
          ]
        }
      }
      this.renderSalaryRange = false
      setTimeout(() => {
        this.renderSalaryRange = true
        this.salaryRangeFilterType = newType
      }, 300)
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
.v-select input {
  cursor: pointer;
}
// div {
//   white-space: pre-wrap;
// }
.logo-space {
  @media (max-width: 425px) {
   margin-top: 0px!important;
  }

  @media (min-width: 426px) and (max-width: 800px) {
   margin-top: 50px!important;
  }

  @media (min-width: 801px) {
   margin-top: 80px!important;
  }

}

.sticky-element {
  position: -webkit-sticky !important; /* Safari */
  position: static !important;
  top: 0;
}
.vue-slider-process {
  background-color: $primary !important;
}

.vue-slider-dot-tooltip-text {
  border-color: $main !important;
  background-color: $primary !important;
}
.vue-slider-dot-tooltip-inner {
  border-color: $primary !important;
  background-color: $primary !important;
}
.image-size {
  width: 100%;

  @media (max-width: 800px) {
    width: 100%;
    object-fit: fill!important;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    // width: 1000px;
    // background-color: darkblue;
  }

  @media (min-width: 426px) and (max-width: 768px) {

    width: 85%;
    object-fit: fill!important;
  }
}

hr.solid {
  border-top: 3px solid #bbb;
}

.divider {
  @media (min-width: 800px) {
    width: 1px;
    background-color: rgba(94, 91, 91, 0.11);
    margin-left: 0px;
    margin-right: 0px;
    height: 5cm;
  }
}
.icon-header {
  margin-left: 5px;
  margin-top: -1px;
}

.typeform-question-container {
  min-height: 30vh;
  max-height: 80vh;
}

hr {
  margin-top: 1rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@import "@core/scss/vue/libs/vue-select.scss";

.filter-button-container {
  // flex: 1;
  // display: flex;
  margin-block: 1rem;
  margin-inline: auto;
  width: 85vw;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  .filter-button-box {
    // display: flex;
    // min-width: 70em;
    margin-bottom: 6px;
    display: flex;
    // flex-wrap: wrap;
    // flex-direction: column;

    -webkit-overflow-scrolling: touch;

    .btn-icon {
      white-space: nowrap;
      margin: 0 0.5em;
    }
    .btn-icon:first-of-type {
      margin: 0 0.5em 0 0;
    }
    .btn-icon:last-of-type {
      margin: 0 0 0 0.5em;
    }
  }
}

.v-select-dark-text {
  .vs__selected {
    color: black;
  }
}

.main-section {
  min-height: 60vh;
}

.modal-title {
  font-family: "DIN-Bold";
}
#modal-lg {
  font-family: "DIN-Regular";
}

.text-white .vs__selected {
  color: $white !important;
}

</style>
