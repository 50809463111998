<template>
  <div class="self-center">

    <img
      class="image-size"
      :src="
        params.banner_image
          ? params.banner_image
          : require('@/assets/images/placeholder/no-image.jpg')
      "
    >

  </div>

</template>

<script>
export default {
  name: 'CellRendererImage',
  props: {
    params: Object,
  },
  computed: {
    chipColor() {
      return value => {
        if (value === 'active') return 'success'
        if (value === 'blocked') return 'danger'
        if (value === 'deactivated') return 'warning'
        return 'primary'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-size{
  width: 150px;
  height: 80px;
}

</style>
